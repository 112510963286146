import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetPhoneAddress, getGraduationMajor, getIttnxm, getEntrance, getLabelpeer, School as _School, Majorlist, checkmobile as _checkmobile, BrochuresList as _BrochuresList, MyCustomerAdd, Customerinfo, getCustomerLevel, MyCustomerUpdate, agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
// import { Structure } from '@/api/updateUserInfo/Usermanagement';
export default {
  data: function data() {
    var _ref;
    return _ref = {
      calls: '',
      MobileAddress: '',
      dialogShow: false,
      client_id: '',
      //是否是项目方   0的话花名必填否则不必填
      dialogPvVisibleDill: false,
      //项目方有撞单 新建成功后弹窗
      theDillEs: [],
      //新建成功后返回的数据
      okdisabled: false,
      repeat_listEven: false,
      repeat_listEs: '',
      //禁止改
      disable: false,
      wxdisable: false,
      mo1disable: false,
      mo2disable: false,
      tgmcdisable: false,
      tgdzdisable: false,
      totle: '',
      noteApp: false,
      pid: this.$route.query.pid,
      customer_id: this.$route.query.customer_id,
      customer_data_id: this.$route.query.customer_data_id,
      agent_type: this.$route.query.agent_type,
      agent_ids: this.$route.query.agent_ids,
      cityId: this.$route.query.cityId,
      projectId: this.$route.query.projectId,
      isPackup: true,
      activeNames: ['1'],
      checked: true,
      isNullCphone: false,
      isSearchLock: true,
      outerVisible: false,
      Collision_sheetMsg: '',
      // 撞单提示信息
      mobile: '',
      hitTheBill: {
        name: '',
        //撞单客户
        landline: '',
        //座机号
        time: '',
        //录入时间
        enteredBy: '',
        //录入人
        type: '',
        //客户类型
        distributor: '',
        //分配人
        personInCharge: '',
        //负责人
        followUpPerson: '' //最后跟进人
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      Show: false,
      territory: '',
      page: 1,
      //花名分页
      Intend: 1,
      //意向专业分页
      GenUle: 1,
      //意向简章分页
      colleges: 1,
      //院校分页
      transfer: '',
      //手机号中转站
      IntendedMajor: [],
      //意向专业
      IntendedStation: [],
      //意向专业中转站
      GeneralRules: [],
      //意向简章
      GeneralStation: [],
      //意向简章中转站
      institu: [],
      //意向院校所选的值
      instituStation: [],
      //意向院校中转站
      flowerNumber: [],
      //花名
      flowerNumberStation: [],
      //花名中转站
      ruleForm: {
        cname: '',
        // 客户名称
        flowerNameId: '',
        //花名id
        category: '',
        //类别
        agent_num: '',
        //花名码
        cphone: '',
        // 联系电话
        cphonetwo: '',
        // 联系电话2
        cphonethree: '',
        // 联系电话3
        cphoneAdress: '',
        // 联系电话1归属地
        cphoneAdress2: '',
        // 联系电话1归属地
        cphoneAdress3: '',
        // 联系电话1归属地
        WeChat: '',
        //微信号
        sex: '0',
        //男女
        Student: 108,
        //学员级别
        customer_level: '',
        educationText: '',
        //当前学历
        projectText: '',
        //报考项目
        entranceText: '',
        //入学时间
        customerLabel: [],
        //客户标签
        department: [195],
        //组织部门
        NextVisitDate: '',
        //下次回访日期
        qqInput: '',
        //QQ号
        mailbox: '',
        //邮箱
        remarks: '',
        //备注
        appended: '',
        //创建附注
        areaOfInterest: '',
        //意向地区
        GraduationMajor: '',
        //毕业专业
        IntendedMajor: [],
        //意向专业
        GeneralRules: [],
        //意向简章
        institu: [],
        //意向院校所选的值
        GraduatedFrom: '',
        //毕业院校
        budget: '',
        //预算
        post: '',
        //岗位
        achievements: '',
        //学术成绩
        address: '',
        //地址
        Age: '',
        //年龄
        Landline: '',
        //座机
        occupation: '',
        //职业
        language: '',
        //语言成绩
        character: '',
        //性格
        industry: '',
        //行业
        Passport: '',
        //护照号
        communicationName: '',
        communicationWebsite: '',
        data_type: '' //数据类型
        // communication: '' //发起沟通的网址
      },
      rules: {
        cname: [{
          required: true,
          message: '请输入客户名称',
          trigger: 'blur'
        }],
        data_type: [{
          required: true,
          message: '请选择数据类型',
          trigger: 'change'
        }],
        projectText: [{
          required: true,
          message: '请选择报考项目',
          trigger: 'change'
        }],
        agent_name: [{
          required: true,
          message: '请选择来源',
          trigger: 'blur'
        }],
        cphone: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, {
          message: '请输入正确的手机号码',
          trigger: ['blur']
        }],
        flowerNameId: [{
          required: true,
          message: '请选择花名',
          trigger: 'blur'
        }],
        Student: [{
          required: true,
          message: '请选择学员级别',
          trigger: 'blur'
        }]
      }
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "client_id", this.$store.state.user.client_id), "StudentLevelEs", []), "loading", false), "Intendeds", []), "flower_Array", []), "GenalRules", []), "chatmodes", []), "educations", []), "projects", []), "entrance", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "customer", []), "organization", []), "Telephone2", false), "Telephone3", false), "plaintext_mobile", '-1'), "plaintext_mobile_one", '-1'), "plaintext_mobile_two", '-1'), "dataTypeOptions", [{
      id: 0,
      value: '',
      label: '请选择',
      disabled: true
    }, {
      id: 1,
      value: 1,
      label: '重庆留学'
    }, {
      id: 2,
      value: 2,
      label: '社科高级'
    }, {
      id: 3,
      value: 3,
      label: '外送数据'
    }]), "is_data_type", ''), "distribution_land_status", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "flag", ''), "platform_name", ''), "page_id", ''), "is_data_type", this.$store.state.user.is_data_type);
  },
  mounted: function mounted() {
    var that = this;
    window.addEventListener('storage', function (item) {
      var names = localStorage.getItem('Incoming');
      if (names == 'ringingIb') {
        var call = localStorage.getItem('call');
        checkCustomerByMobile({
          mobile: call
        }).then(function (item) {
          var data = item.data;
          if (data.flag == 1) {
            that.calls = data.cname;
            that.MobileAddress = data.mobile_address;
          } else {
            that.calls = call;
            that.MobileAddress = data.mobile_address;
          }
          that.dialogShow = true;
        });
      }
      if (item.newValue != 'RINGING') {
        that.dialogShow = false;
        localStorage.setItem('Incoming', '');
      }
    });
    if (this.pid != 1) {
      this.totle = '编辑客户';
      this.GetDetails(this.customer_id, this.customer_data_id);
      this.education();
      if (this.client_id == 0) {
        this.rules.flowerNameId[0].required = true;
        this.rules.data_type[0].required = true;
        this.rules.projectText[0].required = true;
      } else {
        this.rules.flowerNameId[0].required = false;
        this.rules.data_type[0].required = false;
        this.rules.projectText[0].required = false;
      }
    } else {
      this.totle = '新建客户';
      if (this.client_id == 0) {
        this.rules.flowerNameId[0].required = true;
        this.rules.data_type[0].required = true;
        this.rules.projectText[0].required = true;
      } else {
        this.rules.flowerNameId[0].required = false;
        this.rules.data_type[0].required = false;
        this.rules.projectText[0].required = false;
      }
      // 第三方不必填
      // 不参与分配 不必填
      if (this.is_data_type != 1) {
        this.rules.data_type[0].required = false;
        this.dataTypeOptions[0].disabled = false;
      } else {
        this.rules.data_type[0].required = true;
        this.dataTypeOptions[0].disabled = true;
      }
      this.education();
      // this.flowerArray();
    }
    if (this.client_id != 0) {
      this.dataTypeOptions[0].disabled = false;
    }
  },
  methods: {
    DialogClick: function DialogClick() {
      this.dialogShow = false;
    },
    GetDetails: function GetDetails(customer_id, customer_data_id) {
      var _this = this;
      //编辑获取详情
      Customerinfo({
        customer_id: customer_id,
        customer_data_id: customer_data_id
      }).then(function (res) {
        var data = res.data;
        _this.ruleForm.cname = data.cname; // 客户名称
        _this.ruleForm.cphone = data.mobile; // 联系电话
        _this.ruleForm.cphonetwo = data.mobile1;
        _this.ruleForm.cphonethree = data.mobile2;
        if (_this.ruleForm.cphone != '') {
          _this.Show = true;
        }
        if (data.create_id != _this.$store.getters.id) {
          _this.tgmcdisable = true;
        }
        if (data.create_id != _this.$store.getters.id) {
          _this.tgdzdisable = true;
        }
        if (data.mobile1 != '') {
          _this.Telephone2 = true;
        }
        if (data.mobile2 != '') {
          _this.Telephone3 = true;
        }
        if (data.create_id != _this.$store.getters.id) {
          _this.noteApp = true;
        }
        if (data.source_client_id !== '') {
          _this.disable = true;
        }
        // this.disable = data.source_client_id == ''? false:true;
        _this.ruleForm.appended = data.create_notes;
        //意向专业
        _this.IntendedMajor = [];
        _this.ruleForm.IntendedMajor = [];
        data.ittnzy.forEach(function (item) {
          _this.IntendedMajor.push(item);
          _this.ruleForm.IntendedMajor.push(item.id);
        });
        _this.majorDedMaj();

        //意向简章
        _this.GeneralRules = [];
        _this.ruleForm.GeneralRules = [];
        data.ittnjz.forEach(function (item) {
          _this.GeneralRules.push(item);
          _this.ruleForm.GeneralRules.push(item.id);
        });
        _this.BrochuresList();
        //意向院校
        _this.institu = [];
        _this.ruleForm.institu = [];
        data.ittnyx.forEach(function (item) {
          _this.institu.push(item);
          _this.ruleForm.institu.push(item.id);
        });
        _this.School();

        // 花名
        _this.flowerNumber = []; //花名
        data.source_client_info.forEach(function (item) {
          _this.flowerNumber.push(item);
          _this.ruleForm.flowerNameId = item.id;
          _this.ruleForm.category = item.leibie;
          _this.ruleForm.agent_num = item.number;
          _this.flowerArrayEs();
        });
        _this.ruleForm.flowerNameId = data.source_client_id;
        _this.ruleForm.cphonetwo = data.mobile1; // 联系电话2
        _this.ruleForm.cphonethree = data.mobile2; // 联系电话3
        _this.ruleForm.sex = String(data.sex); //男女
        _this.ruleForm.cphoneAdress = data.mobile_address; // 联系电话1归属地
        _this.ruleForm.cphoneAdress2 = data.mobile1_address; // 联系电话1归属地
        _this.ruleForm.cphoneAdress3 = data.mobile2_address; // 联系电话1归属地
        _this.ruleForm.WeChat = data.wxnum; //微信号
        _this.ruleForm.communicationName = data.communication_name;
        _this.ruleForm.communicationWebsite = data.communication_website;
        if (data.wxnum == '') {
          _this.wxdisable = false;
        } else {
          _this.wxdisable = true;
        }
        if (data.mobile1 == '') {
          _this.mo1disable = false;
        } else {
          _this.mo1disable = true;
          _this.show2 = true;
        }
        if (data.mobile2 == '') {
          _this.mo2disable = false;
        } else {
          _this.mo2disable = true;
          _this.show3 = true;
        }
        _this.ruleForm.customer_level = data.customer_level; //学员级别
        _this.ruleForm.Student = data.customer_level; //学员级别
        if (data.graduation_xueli == '0') {
          _this.ruleForm.educationText = ''; //当前学历
        } else {
          _this.ruleForm.educationText = String(data.graduation_xueli); //当前学历
        }
        if (data.ittnxl == '0') {
          _this.ruleForm.projectText = ''; //报考项目
        } else {
          _this.ruleForm.projectText = String(data.ittnxl); //报考项目
        }
        if (data.entrance == '0') {
          _this.ruleForm.entranceText = '';
        } else {
          _this.ruleForm.entranceText = String(data.entrance); //入学时间
        }
        _this.ruleForm.labelpeer = String(data.labelpeer); //客户标签数组
        _this.ruleForm.qqInput = data.qq; //QQ号
        _this.ruleForm.mailbox = data.cemail; //邮箱
        _this.ruleForm.remarks = data.notes; //备注
        _this.ruleForm.areaOfInterest = data.ittngj; //意向地区
        _this.ruleForm.GraduationMajor = data.graduation_major; //毕业专业
        _this.ruleForm.customerLabel = data.labelpeer.split(',').map(function (res) {
          return res;
        });
        _this.ruleForm.GraduatedFrom = data.graduate_school; //毕业院校
        _this.ruleForm.budget = data.budget; //预算
        _this.ruleForm.post = data.post; //岗位
        _this.ruleForm.achievements = data.academic_achievements; //学术成绩
        _this.ruleForm.address = data.detailed_address; //地址
        if (data.age == '0') {
          _this.ruleForm.Age = ''; //年龄
        } else {
          _this.ruleForm.Age = String(data.age); //年龄
        }
        _this.ruleForm.Landline = data.telephone; //座机
        _this.ruleForm.occupation = data.occupation; //职业
        _this.ruleForm.language = data.language_achievement; //语言成绩
        _this.ruleForm.character = data.character; //性格
        _this.ruleForm.industry = data.industry; //行业
        _this.ruleForm.Passport = data.passport_number; //护照号
        // this.ruleForm.communication = data.communication_website; //发起沟通的网址
        _this.ruleForm.data_type = data.data_type != 0 ? data.data_type : '';
        // lq新增
        _this.is_data_type = data.is_data_type;
        // 不参与分配 不必填
        if (_this.is_data_type != 1) {
          _this.rules.data_type[0].required = false;
          _this.dataTypeOptions[0].disabled = false;
        } else {
          _this.rules.data_type[0].required = true;
          _this.dataTypeOptions[0].disabled = true;
        }
        _this.distribution_land_status = data.distribution_land_status;
        _this.flag = data.flag;
        _this.platform_name = data.platform_name;
        _this.page_id = data.page_id;
        _this.plaintext_mobile = '-1';
        _this.plaintext_mobile_one = '-1';
        _this.plaintext_mobile_two = '-1';
      });
    },
    AddSelectDept: function AddSelectDept(val) {
      var _this2 = this;
      if (val != '') {
        this.okdisabled = false;
      }
      this.flower_Array.forEach(function (item) {
        if (val == item.id) {
          _this2.ruleForm.category = item.leibie;
          _this2.ruleForm.agent_num = item.number;
        }
      });
    },
    customerName: function customerName(val) {
      if (val.length >= 2) {
        this.okdisabled = false;
      }
    },
    flowerArrayEs: function flowerArrayEs(val) {
      var _this3 = this;
      //改变花名
      agentFlowerNameList({
        key_word: val
      }).then(function (res) {
        res.data.forEach(function (item) {
          if (val == item.id) {
            _this3.ruleForm.category = item.leibie;
            _this3.ruleForm.agent_num = item.number;
          }
        });
        _this3.flower_Array = res.data;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this4 = this;
      //意向专业
      Majorlist().then(function (res) {
        res.data.major_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxzy = [];
        if (_this4.IntendedMajor != '') {
          for (var i in _this4.IntendedMajor) {
            yxzy.push(_this4.IntendedMajor[i]);
            for (var key in res.data.major_list) {
              yxzy.push(res.data.major_list[key]);
            }
          }
        } else {
          for (var _key in res.data.major_list) {
            yxzy.push(res.data.major_list[_key]);
          }
        }
        _this4.Intendeds = res.data.major_list;
      });
    },
    BrochuresList: function BrochuresList() {
      var _this5 = this;
      //意向简章
      _BrochuresList().then(function (res) {
        res.data.brochures_list.map(function (item) {
          item.id = String(item.id);
        });
        var zxjz = [];
        if (_this5.GeneralRules != '') {
          for (var i in _this5.GeneralRules) {
            zxjz.push(_this5.GeneralRules[i]);
            for (var key in res.data.brochures_list) {
              zxjz.push(res.data.brochures_list[key]);
            }
          }
        } else {
          for (var _key2 in res.data.brochures_list) {
            zxjz.push(res.data.brochures_list[_key2]);
          }
        }
        _this5.GenalRules = res.data.brochures_list;
      });
    },
    School: function School() {
      var _this6 = this;
      //意向院校
      _School().then(function (res) {
        res.data.school_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxyx = [];
        if (_this6.institu != '') {
          for (var i in _this6.institu) {
            yxyx.push(_this6.institu[i]);
            for (var key in res.data.school_list) {
              yxyx.push(res.data.school_list[key]);
            }
          }
        } else {
          for (var _key3 in res.data.school_list) {
            yxyx.push(res.data.school_list[_key3]);
          }
        }
        _this6.chatmodes = res.data.school_list;
      });
    },
    QQverification: function QQverification(val) {
      //QQ号验证
      var regEmail = /^[1-9][0-9]{4,10}$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('QQ号格式不正确');
        this.ruleForm.qqInput = '';
      }
    },
    sendEmail: function sendEmail() {
      //邮箱验证
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.ruleForm.mailbox != '' && !regEmail.test(this.ruleForm.mailbox)) {
        this.$message.error('邮箱格式不匹配');
        this.ruleForm.mailbox = '';
      }
    },
    Landlines: function Landlines(val) {
      //座机号验证
      var regEmail = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('座机号格式不正确');
        this.ruleForm.Landline = '';
      }
    },
    education: function education() {
      var _this7 = this;
      if (this.pid == 1) {
        _School().then(function (res) {
          res.data.school_list.map(function (item) {
            item.id = String(item.id);
          });
          _this7.chatmodes = res.data.school_list;
        });
        agentFlowerNameList({
          page: String(this.page),
          pagesize: '15'
        }).then(function (res) {
          _this7.flower_Array = res.data.data;
        });
        Majorlist().then(function (res) {
          res.data.major_list.map(function (item) {
            item.id = String(item.id);
          });
          _this7.Intendeds = res.data.major_list;
        });
        _BrochuresList().then(function (res) {
          res.data.brochures_list.map(function (item) {
            item.id = String(item.id);
          });
          _this7.GenalRules = res.data.brochures_list;
        });
      }
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this7.StudentLevelEs = res.data;
      });
      getGraduationMajor().then(function (res) {
        //当前学历
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this7.educations = res.data;
      });
      getIttnxm().then(function (res) {
        //报考项目
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this7.projects = res.data;
      });
      getEntrance().then(function (res) {
        //入学时间
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this7.entrance = res.data;
      });
      getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this7.customer = res.data;
      });
    },
    displayShow: function displayShow() {
      // 联系电话2和3的显示
      if (this.Telephone2 == false) {
        this.Telephone2 = true;
      } else {
        this.Telephone3 = true;
      }
    },
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    mobilePhone: function mobilePhone(number) {
      //隐藏清空电话2
      if (number == 2) {
        this.Telephone2 = false;
        this.ruleForm.cphonetwo = '';
        this.plaintext_mobile_one = '';
        this.ruleForm.cphoneAdress2 = '';
      } else {
        this.Telephone3 = false;
        this.ruleForm.cphonethree = '';
        this.plaintext_mobile_two = '';
        this.ruleForm.cphoneAdress3 = '';
      }
    },
    newlyLevel: function newlyLevel(val) {
      //学员级别
      this.ruleForm.Student = this.$refs['refSubCat'].getCheckedNodes()[0].value;
    },
    GETPlaceofownership: function GETPlaceofownership(cphone, number) {
      var _this8 = this;
      //手机号验证/查询归属地
      if (!/^1[3456789]\d{9}$/.test(cphone)) {
        this.$notify({
          title: '警告',
          message: '手机号错误',
          type: 'warning'
        });
        this.Show = false;
      } else {
        if (this.ruleForm.cphone === this.ruleForm.cphonetwo) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.checkmobile(cphone, number);
          this.ruleForm.cphonetwo = '';
        } else if (this.ruleForm.cphone === this.ruleForm.cphonethree) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.ruleForm.cphonethree = '';
          this.checkmobile(cphone, number);
        } else {
          this.checkmobile(cphone, number);
          GetPhoneAddress({
            phone: cphone
          }).then(function (res) {
            _this8.Show = true;
            if (number == 1) {
              _this8.territory = res.data.province;
              _this8.plaintext_mobile = cphone;
              _this8.ruleForm.cphoneAdress = res.data.province + res.data.city;
            } else if (number == 2) {
              _this8.ruleForm.cphoneAdress2 = res.data.province + res.data.city;
              _this8.plaintext_mobile_one = cphone;
            } else if (number == 3) {
              _this8.plaintext_mobile_two = cphone;
              _this8.ruleForm.cphoneAdress3 = res.data.province + res.data.city;
            }
          });
        }
      }
    },
    checkmobile: function checkmobile(phone, number) {
      var _this9 = this;
      _checkmobile({
        mobile: phone,
        customer_id: this.customer_data_id
      }).then(function (res) {
        console.log();
        if (res.data != '' || res.data.length > 0) {
          var data = res.data;
          _this9.hitTheBill.name = data.cname; //撞单客户
          if (number == 1) {
            _this9.hitTheBill.landline = data.mobile; //座机号
            _this9.ruleForm.cphone = '';
          } else if (number == 2) {
            _this9.hitTheBill.landline = data.mobile1; //座机号
            _this9.ruleForm.cphonetwo = '';
          } else if (number == 3) {
            _this9.hitTheBill.landline = data.mobile2; //座机号
            _this9.ruleForm.cphonethree = '';
          }
          _this9.hitTheBill.time = data.create_time; //录入时间
          _this9.hitTheBill.enteredBy = data.create_id; //录入人
          _this9.hitTheBill.type = data.sea_type == 0 ? '私海' : '公海'; //客户类型
          _this9.hitTheBill.personInCharge = data.nowfollowid; //负责人
          _this9.outerVisible = true;
        }
      });
    },
    newlyBuild: function newlyBuild() {
      var _this10 = this;
      //确认按钮
      if (this.isSearchLock) {
        this.isSearchLock = false;
        var thisCopy = this;
        this.okdisabled = true;
        setTimeout(function () {
          thisCopy.isSearchLock = true;
        }, 1000);
        if (this.ruleForm.cname == '') {
          this.okdisabled = false;
          this.$message({
            type: 'warning',
            message: '请务必完善信息！'
          });
        } else {
          if (this.is_data_type == 1 && this.client_id == 0 && (this.ruleForm.data_type == '' || this.ruleForm.data_type == 0)) {
            this.$message({
              message: '数据类型不能为空',
              type: 'warning'
            });
            return;
          }
          if (this.ruleForm.projectText == '' && this.client_id == 0) {
            this.$message({
              message: '报考项目不能为空',
              type: 'warning'
            });
            return;
          }
          if (this.pid == 1) {
            var data = {
              cname: this.ruleForm.cname,
              // 客户名称
              sex: this.ruleForm.sex,
              //男女
              mobile_address: this.ruleForm.cphoneAdress,
              // 联系电话1归属地
              mobile1_address: this.ruleForm.cphoneAdress2,
              // 联系电话1归属地
              mobile2_address: this.ruleForm.cphoneAdress3,
              // 联系电话1归属地
              wxnum: this.ruleForm.WeChat,
              //微信号
              customer_level: String(this.ruleForm.Student),
              //学员级别
              graduation_xueli: this.ruleForm.educationText,
              //当前学历
              ittnxm: this.ruleForm.projectText,
              //报考项目
              entrance: this.ruleForm.entranceText,
              //入学时间
              labelpeer: String(this.ruleForm.customerLabel),
              //客户标签数组
              qq: this.ruleForm.qqInput,
              //QQ号
              cemail: this.ruleForm.mailbox,
              //邮箱
              notes: this.ruleForm.remarks,
              //备注
              ittngj: this.ruleForm.areaOfInterest,
              //意向地区
              graduation_major: this.ruleForm.GraduationMajor,
              //毕业专业
              ittnzy: String(this.ruleForm.IntendedMajor),
              //意向专业
              ittnjz: String(this.ruleForm.GeneralRules),
              //意向简章
              graduate_school: this.ruleForm.GraduatedFrom,
              //毕业院校
              ittnyx: String(this.ruleForm.institu),
              //意向院校所选的值
              budget: this.ruleForm.budget,
              //预算
              post: this.ruleForm.post,
              //岗位
              academic_achievements: this.ruleForm.achievements,
              //学术成绩
              detailed_address: this.ruleForm.address,
              //地址
              age: this.ruleForm.Age,
              //年龄
              create_notes: this.ruleForm.appended,
              telephone: this.ruleForm.Landline,
              //座机
              occupation: this.ruleForm.occupation,
              //职业
              language_achievement: this.ruleForm.language,
              //语言成绩
              character: this.ruleForm.character,
              //性格
              industry: this.ruleForm.industry,
              //行业
              passport_number: this.ruleForm.Passport,
              //护照号
              // communication_website: this.ruleForm.communication, //发起沟通的网址
              mobile_province: this.territory,
              //手机号所属省份
              source_client_id: this.ruleForm.flowerNameId,
              //花名id
              agent_type: this.agent_type,
              //是否为外送客户
              agent_ids: this.agent_ids,
              //外送的项目方名称
              delivery_city_id: this.cityId,
              //外送的项目方城市
              delivery_project: this.projectId,
              //外送的项目方项目
              communication_name: this.ruleForm.communicationName,
              //推广名称
              communication_website: this.ruleForm.communicationWebsite,
              data_type: this.ruleForm.data_type //数据类型
            };
            // 手机号修改以后传 不修改不传
            if (this.plaintext_mobile != -1) {
              data.mobile = this.ruleForm.cphone; //手机号
            }
            if (this.plaintext_mobile_one != -1) {
              data.mobile1 = this.ruleForm.cphonetwo; //手机号1
            }
            if (this.plaintext_mobile_two != -1) {
              data.mobile2 = this.ruleForm.cphonethree; //手机2
            }
            MyCustomerAdd(data).then(function (res) {
              if (res.code == undefined) {
                _this10.dialogPvVisibleDill = true;
                _this10.theDillEs = res.knock;
                if (res.data.repeat.length <= 0) {
                  _this10.repeat_listEven = true;
                  _this10.repeat_listEs = res.data.repeat;
                } else {
                  _this10.repeat_listEven = false;
                  _this10.repeat_listEs = '';
                }
              } else {
                _this10.$notify({
                  title: '成功',
                  message: '新建成功',
                  type: 'success',
                  duration: 2000
                });
                localStorage.setItem('operation', 'opera');
                window.opener = null;
                window.open('about:blank', '_top').close();
              }
            });
          } else {
            var _data = {
              cname: this.ruleForm.cname,
              // 客户名称
              sex: this.ruleForm.sex,
              //男女
              mobile_address: this.ruleForm.cphoneAdress,
              // 联系电话1归属地
              mobile1_address: this.ruleForm.cphoneAdress2,
              // 联系电话1归属地
              mobile2_address: this.ruleForm.cphoneAdress3,
              // 联系电话1归属地
              wxnum: this.ruleForm.WeChat,
              //微信号
              customer_level: String(this.ruleForm.Student),
              //学员级别
              graduation_xueli: this.ruleForm.educationText,
              //当前学历
              ittnxm: this.ruleForm.projectText,
              //报考项目
              entrance: this.ruleForm.entranceText,
              //入学时间
              labelpeer: String(this.ruleForm.customerLabel),
              //客户标签数组
              qq: this.ruleForm.qqInput,
              //QQ号
              cemail: this.ruleForm.mailbox,
              //邮箱
              notes: this.ruleForm.remarks,
              //备注
              ittngj: this.ruleForm.areaOfInterest,
              //意向地区
              graduation_major: this.ruleForm.GraduationMajor,
              //毕业专业
              ittnzy: String(this.ruleForm.IntendedMajor),
              //意向专业
              ittnjz: String(this.ruleForm.GeneralRules),
              //意向简章
              graduate_school: this.ruleForm.GraduatedFrom,
              //毕业院校
              ittnyx: String(this.ruleForm.institu),
              //意向院校所选的值
              budget: this.ruleForm.budget,
              //预算
              post: this.ruleForm.post,
              //岗位
              academic_achievements: this.ruleForm.achievements,
              //学术成绩
              detailed_address: this.ruleForm.address,
              //地址
              age: this.ruleForm.Age,
              //年龄
              create_notes: this.ruleForm.appended,
              //创建附注
              telephone: this.ruleForm.Landline,
              //座机
              occupation: this.ruleForm.occupation,
              //职业
              language_achievement: this.ruleForm.language,
              //语言成绩
              character: this.ruleForm.character,
              //性格
              industry: this.ruleForm.industry,
              //行业
              passport_number: this.ruleForm.Passport,
              //护照号
              // communication_website: this.ruleForm.communication, //发起沟通的网址
              customer_id: String(this.customer_id),
              mobile_province: this.territory,
              //手机号所属省份
              customer_data_id: String(this.customer_data_id),
              source_client_id: this.ruleForm.flowerNameId.toString(),
              //花名id
              communication_name: this.ruleForm.communicationName,
              //推广名称
              communication_website: this.ruleForm.communicationWebsite,
              data_type: this.ruleForm.data_type //数据类型
            };
            // 手机号修改以后传 不修改不传
            if (this.plaintext_mobile != -1) {
              _data.mobile = this.ruleForm.cphone; //手机号
            }
            if (this.plaintext_mobile_one != -1) {
              _data.mobile1 = this.ruleForm.cphonetwo; //手机号1
            }
            if (this.plaintext_mobile_two != -1) {
              _data.mobile2 = this.ruleForm.cphonethree; //手机2
            }
            MyCustomerUpdate(_data).then(function (res) {
              _this10.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
              localStorage.setItem('operation', 'opera');
              window.opener = null;
              window.open('about:blank', '_top').close();
            });
          }
        }
      } else {
        this.$message({
          message: '查询按钮请勿1秒内连续点击！',
          type: 'error'
        });
        return;
      }
    },
    cancelDill: function cancelDill() {
      this.$router.go(-1);
    },
    cancel: function cancel() {
      this.$router.go(-1);
    },
    // 选择数据类型
    changeDataTypeFun: function changeDataTypeFun(val) {
      var _this11 = this;
      var type = [];
      // 根据分配条件配置，数据类型是否必须有值
      // 为是时：
      // 1、数据类型修改成功后，数据进入对应待分配的数据池
      // 2、数据类型选择后弹框二次确认
      // 3、数据类型修改后，自动分配完成前不支持再修改，自动分配完成后支持修改

      // 为否时：
      // 1、数据录入系统成功时即进入对应待分配的数据池
      // 2、数据类型修改不做限制
      if (val != '') {
        type = this.dataTypeOptions.filter(function (item) {
          return item.value == val;
        });
        var arr = [0, 1, 2];
        if (this.is_data_type == 1 && this.distribution_land_status == 0 && this.page_id == '' && (this.flag == '4' && this.platform_name == '易聊' || arr.indexOf(this.flag) != -1)) {
          this.$confirm("\u81EA\u52A8\u5206\u914D\u524D\u4E0D\u53EF\u518D\u4FEE\u6539\u6570\u636E\u7C7B\u578B\uFF0C\u786E\u8BA4\u4FEE\u6539\u4E3A".concat(type[0].label, "\uFF1F"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {}).catch(function () {
            _this11.ruleForm.data_type = '';
          });
        } else {}
      }
    }
  }
};