var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addCustomer" },
    [
      _c(
        "div",
        { staticClass: "case" },
        [
          _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.totle))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.newlyBuild } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", [_vm._v("基本信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { rules: _vm.rules, model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称", prop: "cname" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入客户名称" },
                            on: { change: _vm.customerName },
                            model: {
                              value: _vm.ruleForm.cname,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cname", $$v)
                              },
                              expression: "ruleForm.cname",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话", prop: "cphone" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "30%" },
                            attrs: {
                              disabled: _vm.disable,
                              name: "tel",
                              placeholder: "请输入电话",
                              maxlength: "11",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.GETPlaceofownership(
                                  _vm.ruleForm.cphone,
                                  1
                                )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.cphone,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cphone", $$v)
                              },
                              expression: "ruleForm.cphone",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "26%" },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.ruleForm.cphoneAdress,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cphoneAdress", $$v)
                              },
                              expression: "ruleForm.cphoneAdress",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-button", {
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: { click: _vm.displayShow },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.Telephone2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "电话2" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "30%" },
                                attrs: {
                                  placeholder: "请输入电话",
                                  oninput: (_vm.ruleForm.cphonetwo =
                                    _vm.ruleForm.cphonetwo),
                                  maxlength: "11",
                                  name: "tel",
                                  disabled: _vm.mo1disable,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.GETPlaceofownership(
                                      _vm.ruleForm.cphonetwo,
                                      2
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.cphonetwo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cphonetwo", $$v)
                                  },
                                  expression: "ruleForm.cphonetwo",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "26%" },
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.ruleForm.cphoneAdress2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cphoneAdress2", $$v)
                                  },
                                  expression: "ruleForm.cphoneAdress2",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-minus",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.mobilePhone(2)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { rules: _vm.rules, model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "学员来源(花名)",
                            prop: "flowerNameId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "51%" },
                              attrs: {
                                disabled: _vm.disable,
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请搜索花名",
                                "remote-method": _vm.flowerArrayEs,
                                loading: _vm.loading,
                              },
                              on: { change: _vm.AddSelectDept },
                              model: {
                                value: _vm.ruleForm.flowerNameId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "flowerNameId", $$v)
                                },
                                expression: "ruleForm.flowerNameId",
                              },
                            },
                            _vm._l(_vm.flower_Array, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.flower_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "18%" },
                            attrs: {
                              disabled: "",
                              placeholder: _vm.ruleForm.category,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "微信号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入微信号",
                              disabled: _vm.wxdisable,
                            },
                            model: {
                              value: _vm.ruleForm.WeChat,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "WeChat", $$v)
                              },
                              expression: "ruleForm.WeChat",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.Telephone3
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "电话3" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "30%" },
                                attrs: {
                                  placeholder: "请输入联系电话2",
                                  maxlength: "11",
                                  name: "tel",
                                  disabled: _vm.mo2disable,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.GETPlaceofownership(
                                      _vm.ruleForm.cphonethree,
                                      3
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.cphonethree,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cphonethree", $$v)
                                  },
                                  expression: "ruleForm.cphonethree",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { width: "26%" },
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.ruleForm.cphoneAdress3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "cphoneAdress3", $$v)
                                  },
                                  expression: "ruleForm.cphoneAdress3",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-minus",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.mobilePhone(3)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { rules: _vm.rules, model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "学员级别", prop: "Student" } },
                        [
                          _c("el-cascader", {
                            ref: "refSubCat",
                            staticStyle: {
                              border: "none",
                              "padding-left": "0",
                              width: "69.6666%",
                            },
                            attrs: {
                              options: _vm.StudentLevelEs,
                              props: { expandTrigger: "hover" },
                            },
                            on: { change: _vm.newlyLevel },
                            model: {
                              value: _vm.ruleForm.Student,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "Student", $$v)
                              },
                              expression: "ruleForm.Student",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "QQ" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入QQ号" },
                            on: {
                              change: function ($event) {
                                return _vm.QQverification(_vm.ruleForm.qqInput)
                              },
                            },
                            model: {
                              value: _vm.ruleForm.qqInput,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "qqInput", $$v)
                              },
                              expression: "ruleForm.qqInput",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广项目:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              disabled: _vm.tgmcdisable,
                              size: "255",
                            },
                            model: {
                              value: _vm.ruleForm.communicationName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "communicationName", $$v)
                              },
                              expression: "ruleForm.communicationName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广地址:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              disabled: _vm.tgdzdisable,
                              size: "255",
                            },
                            model: {
                              value: _vm.ruleForm.communicationWebsite,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "communicationWebsite",
                                  $$v
                                )
                              },
                              expression: "ruleForm.communicationWebsite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { rules: _vm.rules, model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据类型:", prop: "data_type" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled:
                                  _vm.is_data_type == 1 &&
                                  _vm.distribution_land_status == 2,
                              },
                              on: { change: _vm.changeDataTypeFun },
                              model: {
                                value: _vm.ruleForm.data_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "data_type", $$v)
                                },
                                expression: "ruleForm.data_type",
                              },
                            },
                            _vm._l(_vm.dataTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  disabled: item.disabled,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入邮箱", type: "email" },
                            on: {
                              change: function ($event) {
                                return _vm.sendEmail(_vm.ruleForm.mailbox)
                              },
                            },
                            model: {
                              value: _vm.ruleForm.mailbox,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "mailbox", $$v)
                              },
                              expression: "ruleForm.mailbox",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建附注:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              placeholder: _vm.noteApp ? "" : "请输入内容",
                              disabled: _vm.noteApp,
                            },
                            model: {
                              value: _vm.ruleForm.appended,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "appended", $$v)
                              },
                              expression: "ruleForm.appended",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.ruleForm.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "remarks", $$v)
                              },
                              expression: "ruleForm.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", [_vm._v("背景意向")]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm, rules: _vm.rules } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "当前学历" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.educationText,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "educationText", $$v)
                                },
                                expression: "ruleForm.educationText",
                              },
                            },
                            _vm._l(_vm.educations, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.value, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "意向地区" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入意向地区" },
                            model: {
                              value: _vm.ruleForm.areaOfInterest,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "areaOfInterest", $$v)
                              },
                              expression: "ruleForm.areaOfInterest",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "报考项目", prop: "projectText" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.projectText,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "projectText", $$v)
                                },
                                expression: "ruleForm.projectText",
                              },
                            },
                            _vm._l(_vm.projects, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.value, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "毕业专业" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.ruleForm.GraduationMajor,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "GraduationMajor", $$v)
                              },
                              expression: "ruleForm.GraduationMajor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "意向专业" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "colleges",
                              attrs: {
                                multiple: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请搜索意向专业",
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.ruleForm.IntendedMajor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "IntendedMajor", $$v)
                                },
                                expression: "ruleForm.IntendedMajor",
                              },
                            },
                            _vm._l(_vm.Intendeds, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "introduction",
                          attrs: { label: "意向简章" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "colleges",
                              attrs: {
                                multiple: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请搜索意向简章",
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.ruleForm.GeneralRules,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "GeneralRules", $$v)
                                },
                                expression: "ruleForm.GeneralRules",
                              },
                            },
                            _vm._l(_vm.GenalRules, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.ruleForm } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "毕业院校" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入毕业院校" },
                            model: {
                              value: _vm.ruleForm.GraduatedFrom,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "GraduatedFrom", $$v)
                              },
                              expression: "ruleForm.GraduatedFrom",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "意向院校" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "colleges",
                              attrs: {
                                multiple: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请输入关键词",
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.ruleForm.institu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "institu", $$v)
                                },
                                expression: "ruleForm.institu",
                              },
                            },
                            _vm._l(_vm.chatmodes, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预算" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入预算" },
                            model: {
                              value: _vm.ruleForm.budget,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "budget", $$v)
                              },
                              expression: "ruleForm.budget",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("h2", { staticClass: "title" }, [_vm._v("其他信息")]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("   (点击展开/收缩)")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.ruleForm } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "性别" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "0" },
                                      model: {
                                        value: _vm.ruleForm.sex,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "sex", $$v)
                                        },
                                        expression: "ruleForm.sex",
                                      },
                                    },
                                    [_vm._v("男")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1" },
                                      model: {
                                        value: _vm.ruleForm.sex,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "sex", $$v)
                                        },
                                        expression: "ruleForm.sex",
                                      },
                                    },
                                    [_vm._v("女")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "入学时间" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.ruleForm.entranceText,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "entranceText",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.entranceText",
                                      },
                                    },
                                    _vm._l(_vm.entrance, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.value,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "岗位" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入岗位" },
                                    model: {
                                      value: _vm.ruleForm.post,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "post", $$v)
                                      },
                                      expression: "ruleForm.post",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "学术成绩" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入学术成绩" },
                                    model: {
                                      value: _vm.ruleForm.achievements,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "achievements",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.achievements",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.ruleForm } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年龄" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入年龄",
                                      oninput: (_vm.ruleForm.Age =
                                        _vm.ruleForm.Age.replace(/[^\d]/g, "")),
                                      maxlength: "3",
                                    },
                                    model: {
                                      value: _vm.ruleForm.Age,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "Age", $$v)
                                      },
                                      expression: "ruleForm.Age",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "座机" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入座机" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.Landlines(
                                          _vm.ruleForm.Landline
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.ruleForm.Landline,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "Landline", $$v)
                                      },
                                      expression: "ruleForm.Landline",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "职业" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入职业" },
                                    model: {
                                      value: _vm.ruleForm.occupation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "occupation",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.occupation",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "语言成绩" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入语言成绩" },
                                    model: {
                                      value: _vm.ruleForm.language,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "language", $$v)
                                      },
                                      expression: "ruleForm.language",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.ruleForm } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "性格" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入性格" },
                                    model: {
                                      value: _vm.ruleForm.character,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "character", $$v)
                                      },
                                      expression: "ruleForm.character",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "行业" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入行业" },
                                    model: {
                                      value: _vm.ruleForm.industry,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "industry", $$v)
                                      },
                                      expression: "ruleForm.industry",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "护照号码" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入护照号码" },
                                    model: {
                                      value: _vm.ruleForm.Passport,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "Passport", $$v)
                                      },
                                      expression: "ruleForm.Passport",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "地址" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入地址" },
                                    model: {
                                      value: _vm.ruleForm.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "address", $$v)
                                      },
                                      expression: "ruleForm.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form",
                            { attrs: { model: _vm.ruleForm } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户标签:" } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.ruleForm.customerLabel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "customerLabel",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.customerLabel",
                                      },
                                    },
                                    _vm._l(_vm.customer, function (item) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: item.id,
                                          staticStyle: {
                                            "margin-right": "18px",
                                          },
                                          attrs: { label: item.id },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.outerVisible },
          on: {
            "update:visible": function ($event) {
              _vm.outerVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("撞单客户：" + _vm._s(_vm.hitTheBill.name))]),
          _vm._v(" "),
          _c("p", [_vm._v("座机号：" + _vm._s(_vm.hitTheBill.landline))]),
          _vm._v(" "),
          _c("p", [_vm._v("录入时间：" + _vm._s(_vm.hitTheBill.time))]),
          _vm._v(" "),
          _c("p", [_vm._v("录入人：" + _vm._s(_vm.hitTheBill.enteredBy))]),
          _vm._v(" "),
          _c("p", [_vm._v("客户类型：" + _vm._s(_vm.hitTheBill.type))]),
          _vm._v(" "),
          _c("p", [_vm._v("负责人：" + _vm._s(_vm.hitTheBill.personInCharge))]),
          _vm._v(" "),
          _c("div", { staticClass: "prohibit" }, [
            _c("p", [_vm._v("该客户已存在，禁止重复录入")]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.outerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPvVisibleDill,
            title: "提示",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPvVisibleDill = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "theDill_list" },
            [
              _c("p", { staticClass: "title" }, [_vm._v("撞单提示")]),
              _vm._v(" "),
              _vm._l(_vm.theDillEs, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "theDill" },
                  [
                    _c("p", { staticStyle: { color: "#ff6f6f" } }, [
                      _vm._v(
                        "\n                    项目方\n                    "
                      ),
                      _c("span", [_vm._v(_vm._s(item.qname))]),
                      _vm._v(" 已存在该客户信息。\n                "),
                    ]),
                    _vm._v(" "),
                    _vm._l(item.list, function (event, keyEs) {
                      return _c(
                        "div",
                        { key: keyEs, staticClass: "theDill_Information" },
                        [
                          _c("p", [
                            _vm._v(
                              "\n                        客户名称：\n                        "
                            ),
                            _c("span", [_vm._v(_vm._s(event.canme))]),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                        客户电话：\n                        "
                            ),
                            _c("span", [_vm._v(_vm._s(event.mobile))]),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                        录入时间：\n                        "
                            ),
                            _c("span", [_vm._v(_vm._s(event.create_time))]),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.repeat_listEven
            ? _c("div", { staticClass: "repeat" }, [
                _c("p", { staticClass: "title" }, [_vm._v("外送提示")]),
                _vm._v(" "),
                _c("div", { staticClass: "repeat_list" }, [
                  _c("p", [_vm._v(_vm._s(_vm.repeat_listEs))]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cancelDill } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dialogShow
        ? _c("div", { staticClass: "drag" }, [
            _c(
              "div",
              {
                directives: [{ name: "drag", rawName: "v-drag" }],
                staticClass: "drag_box",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("来电提醒")]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "iconfont icon-cha",
                    on: { click: _vm.DialogClick },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "Main" }, [
                  _vm._v("\n                您有\n                "),
                  _c("span", [_vm._v(_vm._s(_vm.calls))]),
                  _vm._v(" "),
                  _c("span", [_vm._v("（" + _vm._s(_vm.MobileAddress) + "）")]),
                  _vm._v("的来电请您到客户列表电话条处接听\n            "),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }